<template>
    <div>
        <div class="wz">当前位置：首页 > 资讯 > {{detail.title}}</div>
        <div class="newsbox">
            <div class="newsright">
                <div class="righttitle">{{detail.title}}</div>
                <div class="rightmessage">发布：{{detail.creationTime}}  发布人：{{detail.author}}</div>
                <div class="show" v-html="detail.articleContent">
                    {{detail.articleContent}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from "@/api/user";
export default {
    data(){
        return{
            classlist:[],
            showid:0,
            detail:{
                articleContent:"",
                title:"",
                creationTime:"",
                author:""
            }
        }
    },
    created(){
        this.showid=this.$route.query.id
        this.getDetail()
    },
    methods:{
        getDetail(){
            userRequest.getnewsById({id:this.showid}).then(res=>{
                console.log(res)
                this.detail.articleContent=res.data.articleContent
                this.detail.title=res.data.title
                this.detail.creationTime=res.data.creationTime
                this.detail.author=res.data.author
            })
        }
    }
}
</script>
<style scoped>

.newsbox{ width: 1198px; margin:0  auto; padding:10px 0 20px 0; display: flex; justify-content: space-between;}
.newsleft{ background: #ffffff; width: 250px; box-sizing: border-box; padding:15px;}
.newsleft ul{ display: block;}
.newsright{ background: #ffffff; width: 100%;box-sizing: border-box; padding:25px;}
.newsleft ul li{ display: block; overflow: hidden;cursor: pointer; height: 50px; line-height: 50px;display: block; text-align: center; border-bottom:#f0f0f0 solid 1px;}
.newsleft ul li:hover{color:#409eff;}
.righttitle{ font-size:18px; font-weight: bold; text-align: center;}
.show{ padding:18px 10px 0 10px; color:#444; line-height: 170%; font-size: 16px; }
.rightmessage{ text-align: center; font-size: 12px; padding:15px 0; border-bottom:#f0f0f0 solid 1px; color:#999; margin-top:15px;}
.wz{ width: 1198px; padding:18px 0; margin:0 auto; color:#999;}
.show /deep/ img{ max-width: 80%; display: block; margin:15px auto;}
.show /deep/ table{ border-right:#eee solid 1px!important;border-bottom:#eee solid 1px!important;}
.show /deep/ table tr th{border-left:#eee solid 1px!important;border-top:#eee solid 1px!important; padding:3px;}
.show /deep/ table tr td{border-left:#eee solid 1px!important;border-top:#eee solid 1px!important;padding:3px;}
</style>